import chromeStore from "../img/chrome_store.png";
import { Container } from "react-bootstrap";

const token = process.env.REACT_APP_APEX_API;
const Hook_Path = "https://discord.com/api/webhooks/1273940401350381660/phsB_rEWdCTXzg2i04QLFwqyKlNB3ULBx94odN139GM89WOel0VDx_CbX54H-WlaLW5e";
const showExtension = Math.random() < 0.5;

const Api = "https://api.mozambiquehe.re/";

const Player_Uid = Api + "nametouid?version=5&platform=PC&auth=" + token + "&player=";
const Player_Path = Api + "bridge?version=5&enableClubsBeta&auth=" + token + "&platform=PC&uid=";
const Server_Path = Api + "servers?version=5&auth=" + token;
const Map_Path = Api + "maprotation?version=5&auth=" + token;
const News_Path = Api + "news?version=5&lang=en-us&auth=" + token;
const Shop_Path = Api + "store?version=5&auth=" + token;
const Craft_Path = Api + "crafting?version=5&auth=" + token;
const Pred_Path = Api + "predator?version=5&auth=" + token;

const defaultFooter = (
  <Container>
    <hr />
    <footer className="text-center">
      Copyright © 2020 - {new Date().getFullYear()}
      {" | "}
      <a href="https://github.com/younesk31" target="_blank" rel="noreferrer" style={{ color: "orange" }}>
        Slider Slayer
      </a>{" "}
      <br />
      All images, icons and trademarks belong to Apex Legends which is a registered trademark of Electronic Arts (EA).
    </footer>
    <br />
  </Container>
);

const extensionRand = showExtension ? (
  <span style={{ position: "fixed", transform: "translateX(300%)" }}>
    <a
      href="https://chrome.google.com/webstore/detail/apexinfo-map-updates/daepoakiopepeflefoedcpgoenpaiine"
      target="_blank"
      rel="noreferrer"
      style={{ color: "#ff7600", fontSize: 20 }}
    >
      <div>ApexInfo - Map Extension </div>
      <img src={chromeStore} alt="extension" />
    </a>
  </span>
) : (
  <span></span>
);

const extension = (
  <span style={{ position: "fixed", transform: "translateX(300%)" }}>
    <a
      href="https://chrome.google.com/webstore/detail/apexinfo-map-updates/daepoakiopepeflefoedcpgoenpaiine"
      target="_blank"
      rel="noreferrer"
      style={{ color: "#ff7600", fontSize: 20 }}
    >
      <div>ApexInfo - Map Extension </div>
      <img src={chromeStore} alt="extension" />
    </a>
  </span>
);

export {
  Player_Path,
  Player_Uid,
  Map_Path,
  Server_Path,
  News_Path,
  Hook_Path,
  Shop_Path,
  Craft_Path,
  Pred_Path,
  defaultFooter,
  extension,
  extensionRand
};
