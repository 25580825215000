import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Nav, Container, Navbar, Image, Card } from "react-bootstrap";
import NewsInfo from "./NewsInfo";
import MapInfo from "./MapInfo";
import PredatorInfo from "./PredatorInfo";
import ServerInfo from "./ServerInfo";
// import PlayerInfo from "./PlayerInfo";
// import Updates from "./Updates";
// import ShopInfo from "./Shop";
// import CraftInfo from "./CraftInfo";
import logo from "../img/logo.svg";
import notFound404 from "../img/pagenotfound.png";
import ".././css/rainbow.css";
export default function DefaultRoute() {
  const [data, setData] = useState();
  const [isDataReady, setDataReady] = useState(false);

  useEffect(() => {
    var savedMap = localStorage.getItem("map");
    var savedTime = localStorage.getItem("time");
    if (savedMap !== null && savedTime !== null) {
      setData({
        map: savedMap,
        time: savedTime,
      });
      setDataReady(true);
    } else {
      setData({
        map: savedMap,
        time: savedTime,
      });
      localStorage.setItem("map", savedMap);
      localStorage.setItem("time", savedTime);
      setDataReady(true);
    }
  }, [isDataReady]);

  var time;
  if (isDataReady && data.time !== null && data.time !== "null" && typeof data.time === "string") {
    time = data.time;
    const regex = /(\d+) day[s]* - (\d+):(\d+):(\d+) hours/;
    const match = data.time.match(regex);

    if (match) {
      const days = parseInt(match[1], 10);
      const hours = parseInt(match[2], 10);
      const minutes = parseInt(match[3], 10);
      const seconds = parseInt(match[4], 10);
      const totalSeconds = days * 24 * 60 * 60 + hours * 60 * 60 + minutes * 60 + seconds;

      if (totalSeconds > 24 * 60 * 60) {
        time = `more than a day`;
      }
    }
  }

  return (
    <Router>
      <div>
        {isDataReady && <Header brMap={data.map} mapTime={time} />}
        <div className="content">
          <Routes>
            <Route path="/" element={<NewsInfo />} />
            <Route path="/map" element={<MapInfo />} />
            <Route path="/predator" element={<PredatorInfo />} />
            <Route path="/server" element={<ServerInfo />} />
            {/* <Route path="/player" element={<PlayerInfo />} /> */}
            {/* <Route path="/shop" element={<ShopInfo />} /> */}
            {/* <Route path="/crafter" element={<CraftInfo />} /> */}
            {/* <Route path="/updates" element={<Updates />} /> */}
            {/* Page Not Found */}
            <Route path="*" element={<NotFound />} />
          </Routes>
        </div>
      </div>
    </Router>
  );
}

const NotFound = () => {
  return (
    <div className="text-center">
      <Card className="bg-dark text-white" style={{ borderRadius: "unset" }}>
        <Card.Img
          src={notFound404}
          style={{ maxHeight: "calc(100vh - 73px)", borderRadius: "unset" }}
          alt="Card image"
        />
        <Card.ImgOverlay style={{ top: "55vh" }}>
          <Card.Title style={{ fontSize: "xx-large" }}>
            Click <a href="./">here</a> to go back to the home page
          </Card.Title>
        </Card.ImgOverlay>
      </Card>
    </div>
  );
};

const Header = ({ brMap, mapTime }) => {
  return (
    <Navbar sticky="top" collapseOnSelect expand="lg" bg="dark" variant="dark">
      <Container className="text-center">
        <Navbar.Brand href="/">
          <Image src={logo} width={60} height={45} /> Apex Info
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link href="/map">
              <b>Map</b>
            </Nav.Link>
            <Nav.Link href="/predator">
              <b>Road To Predator</b>
            </Nav.Link>
            <Nav.Link href="/server">
              <b>Server Status</b>
            </Nav.Link>
            {/* <Nav.Link disabled href="/player">
              <b>Stats</b>
            </Nav.Link>
            <Nav.Link disabled href="/shop">
              <b>Shop</b>
            </Nav.Link>
            <Nav.Link disabled href="/crafter">
              <b>Crafter</b>
            </Nav.Link>
            <Nav.Link disabled href="/updates">
              <b>Updates</b>
            </Nav.Link> */}
          </Nav>

          <Nav>
            <Nav.Link href="/map">
              {brMap === null || brMap === "null" || mapTime === null || mapTime === "null" ? (
                <span></span>
              ) : (
                <span>
                  Pubs Map: <b>{brMap}</b> for <b>{mapTime}</b>
                </span>
              )}
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};