const ErrorStatusHandler = (statusCode) => {
  switch (statusCode) {
    case 400:
      return "Try reloading the page - [Code Net]";
    case 405:
      return "Try reloading the page - [Code Leaf]";
    case 410:
      return "Try reloading the page - [Code Snake]";
    case 429:
      return "Try reloading the page - [Code Shoe]";
    case 500:
      return "Try reloading the page - [Code Tap]";
    default:
      return "Try reloading the page - [Code Net]";
  }
};

export default ErrorStatusHandler;
