import { News_Path, defaultFooter, extension } from "./Urls";
import React, { useState, useEffect } from "react";
import { Image, Card, Row, Col, Container, Spinner } from "react-bootstrap";
// import AdsComponent from "./GoogleAds";
import ErrorStatusHandler from "./ErrorHelper/ErrorStatusHandler";
import Placeholder from "react-bootstrap/Placeholder";
import Nessie from "../img/nessie.gif";
import "../css/cardtext.css";
import Typewriter from "typewriter-effect";

function NewsInfo() {
  const [isDataReady, setDataReady] = useState(false);
  const [newsdata, setNewsData] = useState([]);
  const [error, setError] = useState();

  useEffect(() => {
    setDataReady(false);
    fetch(News_Path)
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          setDataReady(false);
          setError(ErrorStatusHandler(res.status));
        }
      })
      .then((result) => {
        setNewsData(result);
        setDataReady(true);
      })
      .catch((error) => {
        //Debug
        //setError(String(error));
      });
  }, []);
  var isMobile = /Android|webOS|Mobile|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

  var perChunk = 3;
  if (isDataReady && error == null) {
    if (isMobile) perChunk = 2;
    if (newsdata !== undefined || newsdata !== null) {
      var result = newsdata.reduce((resultArray, item, index) => {
        const chunkIndex = Math.floor(index / perChunk);

        if (!resultArray[chunkIndex]) {
          resultArray[chunkIndex] = [];
        }
        resultArray[chunkIndex].push(item);
        return resultArray;
      }, []);
    }
  }

  const preloadingCol = isMobile ? (
    <Col>
      <Card style={styles.card}>
        <Card.Link>
          <div className="text-center">
            <Image src={Nessie} fluid width={235} />
          </div>
        </Card.Link>
        <Card.Footer className="cardfooter">
          <Placeholder as="p" animation="glow">
            <Placeholder xs={12} />
            <Placeholder xs={10} />
            <Placeholder xs={8} />
          </Placeholder>
        </Card.Footer>
      </Card>
    </Col>
  ) : (
    <Col>
      <Card style={styles.card}>
        <Card.Link>
          <div className="text-center">
            <Image src={Nessie} fluid width={235} />
          </div>
        </Card.Link>
        <Card.Footer className="cardfooter">
          <Placeholder as="p" animation="glow">
            <Placeholder xs={10} />
            <Placeholder xs={8} />
          </Placeholder>
        </Card.Footer>
      </Card>
    </Col>
  );

  if (!isDataReady || error != null) {
    return (
      <div className="text-center">
        <Container>
          {extension}
          <br />
          <h1>
            {error != null ? (
              <div style={{ color: "red" }}>
                {error}
                <Spinner animation="grow" size="sm" variant="danger" />
              </div>
            ) : (
              <>
                <h1>
                  <b>
                    Loading
                    <Spinner animation="grow" size="sm" variant="danger" />
                    <Spinner animation="grow" size="sm" variant="danger" />
                    <Spinner animation="grow" size="sm" variant="danger" />
                  </b>
                </h1>
              </>
            )}
          </h1>
          {isMobile ? (
            <Row>
              {preloadingCol}
              {preloadingCol}
              <div>
                <hr />
              </div>
              {preloadingCol}
              {preloadingCol}
              <div>
                <hr />
              </div>
              {preloadingCol}
              {preloadingCol}
              <div>
                <hr />
              </div>
              {preloadingCol}
              {preloadingCol}
            </Row>
          ) : (
            <Row>
              {preloadingCol}
              {preloadingCol}
              {preloadingCol}
              <div>
                <hr />
              </div>
              {preloadingCol}
              {preloadingCol}
              {preloadingCol}
              <div>
                <hr />
              </div>
              {preloadingCol}
              {preloadingCol}
              {preloadingCol}
              <div>
                <hr />
              </div>
              {preloadingCol}
              {preloadingCol}
              {preloadingCol}
            </Row>
          )}
          <hr />
        </Container>
        {/* Footer */}
        {defaultFooter}
        <br />
      </div>
    );
  } else if (isDataReady) {
    return (
      <div>
        <Container>
          <div className="text-center">
            {/* Extension */}
            {extension}
            {/* <Card border="dark">
              <Ratio aspectRatio={"21x9"}>
                <iframe
                  src="https://www.youtube.com/embed/WUFdoWZUBgo?autoplay=1"
                  title="YouTube video"
                  frameBorder="0"
                  allow="accelerometer;autoplay;gyroscope;"
                ></iframe>
              </Ratio>
            </Card> */}
            <br />
            <h1>
              <b>
                <Typewriter
                  options={{
                    strings: ["Latest Respawn News", "Latest Apex News"],
                    autoStart: true,
                    loop: true,
                    pauseFor: 2000,
                    cursor: "|",
                  }}
                />
              </b>
            </h1>
          </div>
          <Row>
            {result[0].map((element, idx) => (
              <Col key={idx}>
                <Card style={styles.card}>
                  <Card.Link href={element.link} target="_blank">
                    <Card.Img style={styles.cardImage} variant="bottom" src={element.img} />
                  </Card.Link>
                  <Card.Footer className="cardfooter">{element.short_desc}</Card.Footer>
                </Card>
              </Col>
            ))}
            <div>
              <hr />
              {/* <AdsComponent dataAdSlot="2644507842" /> */}
            </div>
            {result[1].map((element, idx) => (
              <Col key={idx}>
                <Card style={styles.card}>
                  <Card.Link href={element.link} target="_blank">
                    <Card.Img style={styles.cardImage} variant="bottom" src={element.img} />
                  </Card.Link>
                  <Card.Footer className="cardfooter">{element.short_desc}</Card.Footer>
                </Card>
              </Col>
            ))}
            <div>
              <hr />
              {/* <AdsComponent dataAdSlot="5270671182" /> */}
            </div>
            {result[2].map((element, idx) => (
              <Col key={idx}>
                <Card style={styles.card}>
                  <Card.Link href={element.link} target="_blank">
                    <Card.Img style={styles.cardImage} variant="bottom" src={element.img} />
                  </Card.Link>
                  <Card.Footer className="cardfooter">{element.short_desc}</Card.Footer>
                </Card>
              </Col>
            ))}
            <div>
              <hr />
              {/* <AdsComponent dataAdSlot="1507558136" /> */}
            </div>
            {result[3].map((element, idx) => (
              <Col key={idx}>
                <Card style={styles.card}>
                  <Card.Link href={element.link} target="_blank">
                    <Card.Img style={styles.cardImage} variant="bottom" src={element.img} />
                  </Card.Link>
                  <Card.Footer className="cardfooter">{element.short_desc}</Card.Footer>
                </Card>
              </Col>
            ))}
          </Row>
        </Container>

        {/* Footer */}
        {defaultFooter}
      </div>
    );
  }
}

const styles = {
  card: {
    background: 0,
    border: null,
    borderRadius: 10,
  },
  cardImage: {
    borderRadius: 4,
    borderBottomLeftRadius: "unset",
    borderBottomRightRadius: "unset",
  },
};

export default NewsInfo;
