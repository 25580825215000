import React, { useState, useEffect } from "react";
import { Pred_Path, defaultFooter, extension } from "./Urls";
import { Spinner, Container, Image, Row, Col } from "react-bootstrap";
import ErrorStatusHandler from "./ErrorHelper/ErrorStatusHandler";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import DesktopWindowsIcon from "@mui/icons-material/DesktopWindows";
import SportsEsportsIcon from "@mui/icons-material/SportsEsports";
import Nessie from "../img/nessie.gif";

function PredatorInfo() {
  const [isDataReady, setDataReady] = useState(false);
  const [predData, setPredData] = useState();
  const [error, setError] = useState();

  useEffect(() => {
    fetch(Pred_Path)
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          setDataReady(false);
          setError(ErrorStatusHandler(res.status));
        }
      })
      .then((result) => {
        setPredData({
          ////////*  BR
          //Pc
          PcRankBr: result.RP.PC.foundRank,
          PcRpBr: result.RP.PC.val,
          PcUpdateBr: result.RP.PC.updateTimestamp,
          PcMastersBr: result.RP.PC.totalMastersAndPreds,
          //Playstation
          PsRankBr: result.RP.PS4.foundRank,
          PsRpBr: result.RP.PS4.val,
          PsMastersBr: result.RP.PS4.totalMastersAndPreds,
          //Xbox
          XRankBr: result.RP.X1.foundRank,
          XRpBr: result.RP.X1.val,
          XMastersBr: result.RP.X1.totalMastersAndPreds,
        });
        setDataReady(true);
      })
      .catch((error) => {
        //Debug
        //setError(String(error));
      });
  }, []);

  function calcTimeLeft(duration) {
    let currentTime = Math.floor(new Date().getTime() / 1000);
    var num = parseInt(currentTime - duration, 10);
    var minutes = Math.floor((num % 3600) / 60);
    return "" + minutes + " mins ago";
  }

  if (!isDataReady || error != null) {
    return (
      <div className="text-center">
        <Image src={Nessie} fluid />

        <h1>
          {error != null && (
            <div style={{ color: "red" }}>
              {error}
              <Spinner animation="grow" size="sm" variant="danger" />
            </div>
          )}
          {error == null && (
            <div>
              Loading <b>Predator</b> data
              <Spinner animation="grow" size="sm" variant="danger" />
            </div>
          )}
        </h1>
      </div>
    );
  } else if (isDataReady) {
    return (
      <div className="text-center">
        {extension}
        <br />
        <Container>
          <h5>
            Your road to <b>Predator</b> starts here!
          </h5>
          <div align="center">
            <Row>
              <Col>
                <List sx={{ width: "100%", maxWidth: 400 }}>
                  <hr />
                  <h5>Ranked Battle Royal</h5>
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar>
                        <DesktopWindowsIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={predData.PcRpBr + " RP"} />
                    <ListItemText
                      align="center"
                      primary={"PC"}
                      secondary={
                        predData.PcMastersBr < 750
                          ? predData.PcMastersBr + " Predators"
                          : predData.PcMastersBr - 750 + " Masters*"
                      }
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar>
                        <SportsEsportsIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={predData.PsRpBr + " RP"} />
                    <ListItemText
                      align="center"
                      primary={"Playstation"}
                      secondary={
                        predData.PsMastersBr < 750
                          ? predData.PsMastersBr + " Predators"
                          : predData.PsMastersBr - 750 + " Masters*"
                      }
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar>
                        <SportsEsportsIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={predData.XRpBr + " RP"} />
                    <ListItemText
                      align="center"
                      primary={"Xbox"}
                      secondary={
                        predData.XMastersBr < 750
                          ? predData.XMastersBr + " Predators"
                          : predData.XMastersBr - 750 + " Masters*"
                      }
                    />
                  </ListItem>
                </List>
              </Col>
            </Row>
          </div>
          <br />

          <div align="left" style={{ fontSize: 12 }}>
            Data is updated hourly.
            <div>
              * The total amount of masters on each platform and is guessed using the highest ranking found in the{" "}
              <a href="https://apexlegendsstatus.com/" target="_blank" rel="noreferrer">
                ALS
              </a>{" "}
              database.{" "}
              <div>
                The number found is very likely to be under-estimated, as all masters players are not in the database.
              </div>{" "}
              However, if the last player to hit master is in the database, the ranking will be 100% accurate.
            </div>
          </div>

          <div align="right">
            <b>Updated {calcTimeLeft(predData.PcUpdateBr)}</b>
          </div>
        </Container>

        {/* Footer */}
        {defaultFooter}
      </div>
    );
  }
}

export default PredatorInfo;
