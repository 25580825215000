import React from "react";
import { createRoot } from 'react-dom/client';
import Default from "./comp/DataCollector";
const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  // <React.StrictMode>
  <Default />
  // </React.StrictMode >
);
