import React, { useState, useEffect } from "react";
import { Server_Path, defaultFooter, extension } from "./Urls";
import { Image, Row, Container, Spinner, Card } from "react-bootstrap";
import ServerStatus from "./ServerInfoHelper/ServerStatus";
import ErrorStatusHandler from "./ErrorHelper/ErrorStatusHandler";
import Nessie from "../img/nessie.gif";

function ServerInfo() {
  const [isDataReady, setDataReady] = useState(false);
  const [serverData, setServerData] = useState([]);
  const [error, setError] = useState();

  useEffect(() => {
    setDataReady(false);
    fetch(Server_Path)
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          setDataReady(false);
          setError(ErrorStatusHandler(res.status));
        }
      })
      .then((result) => {
        setServerData({ ...result });
        setDataReady(true);
      })
      .catch((error) => {
        //Debug
        //setError(String(error));
      });
  }, []);

  function calcTimeLeft(duration) {
    let currentTime = Math.floor(new Date().getTime() / 1000);
    var num = parseInt(currentTime - duration, 10);
    var minutes = Math.floor((num % 3600) / 60);
    if (minutes === 1) return minutes + " min";
    return minutes + " mins";
  }

  const categoryTitles = {
    ApexOauth_Crossplay: "Crossplay Services",
    EA_accounts: "EA Accounts",
    EA_novafusion: "EA Backend",
    Origin_login: "EA Authentication",
    otherPlatforms: "Other Platforms",
    selfCoreTest: "Others",
  };

  if (!isDataReady || error != null) {
    return (
      <div className="text-center">
        <Image src={Nessie} fluid />

        <h1>
          {error != null && (
            <div style={{ color: "red" }}>
              {error}
              <Spinner animation="grow" size="sm" variant="danger" />
            </div>
          )}
          {error == null && (
            <div>
              Loading <b>Server Connectivity</b> Information
              <Spinner animation="grow" size="sm" variant="danger" />
            </div>
          )}
        </h1>
      </div>
    );
  } else if (isDataReady) {
    return (
      <div className="text-center">
        {/* Extension */}
        {extension}

        <Container>
          <div>
            <br />
            {/* <h4>Server Connectivity Information</h4> */}
            <div>
              Updated <b>{calcTimeLeft(serverData.Origin_login["EU-West"].QueryTimestamp)}</b> ago
            </div>
          </div>
          <hr />
          <Row>
            {Object.entries(serverData).map(([category, subData]) => (
              <React.Fragment key={category}>
                <Card.Title>{categoryTitles[category] || category}</Card.Title>
                {Object.entries(subData).map(([server, data]) => (
                  <ServerStatus key={server} title={server} status={data.Status} ping={data.ResponseTime} />
                ))}
              </React.Fragment>
            ))}
          </Row>
        </Container>

        {/* Footer */}
        {defaultFooter}
      </div>
    );
  }
}

export default ServerInfo;
