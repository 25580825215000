import React from "react";
import { Card, Col } from "react-bootstrap";

const ServerStatus = ({ title, status, ping }) => {
  const getStatusStyle = (value, threshold, color) => {
    return value > threshold ? { color: color } : { color: "green" };
  };

  return (
    <Col>
      <Card>
        <Card.Body>
          <Card.Title>{title}</Card.Title>
        </Card.Body>
        <Card.Footer>
          {status ? (
            <>
              {"Status: "}
              <b style={getStatusStyle(status, 90, "red")}>{status}</b>
            </>
          ) : (
            <div></div>
          )}
          <br />
          {ping ? (
            <>
              {"Ping: "}
              <b style={getStatusStyle(ping, 90, "orange")}>{ping}</b>
            </>
          ) : (
            <div></div>
          )}
        </Card.Footer>
      </Card>
    </Col>
  );
};

export default ServerStatus;
