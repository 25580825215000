import React, { useState, useEffect } from "react";
import { Hook_Path } from "./Urls";
import DefaultRoute from "./DefaultRoute";

function DataCollector() {
  const [dataFetchComplete, setDataFetchComplete] = useState(false);

  useEffect(() => {
    const hasBeenLogged = sessionStorage.getItem("hasBeenLogged");

    if (!hasBeenLogged) {
      fetch("https://ipapi.co/json/")
        .then((res) => res.json())
        .then((result) => {
          const msg = {
            embeds: [
              {
                "title": `Visitor From - ${result.country_name}`,
                "description": `${result.ip} visited from ${result.city} / ${result.region}\nTime Zone: ${result.timezone}\nISP: ${result.org}`,
              },
            ],
          };
          const postRequest = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(msg),
          };

          if (result) {
            fetch(Hook_Path, postRequest);
            sessionStorage.setItem("hasBeenLogged", "true");
          }
        })
        .catch((error) => {
          //   console.error("Error fetching data:", error);
        });
      setDataFetchComplete(true);
    } else {
      sessionStorage.setItem("SkippingCheck", "true");
      setDataFetchComplete(true);
    }
  }, []);

  if (dataFetchComplete) {
    return <DefaultRoute />;
  }
}

export default DataCollector;
