import React, { useState, useEffect } from "react";
import { Map_Path, defaultFooter, extension } from "./Urls";
// import AdsComponent from "./GoogleAds";
import ErrorStatusHandler from "./ErrorHelper/ErrorStatusHandler";
import { Container, Image, Spinner } from "react-bootstrap";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import ButtonBase from "@mui/material/ButtonBase";
// import Tooltip from "@mui/material/Tooltip";
//BR Maps
import StormPoint from "../img/Storm_Point.png";
import WorldsEdge from "../img/Worlds_Edge.png";
import KingsCanyon from "../img/Kings_Canyon.png";
import Olympus from "../img/Olympus.png";
import BrokenMoon from "../img/Broken_Moon.png";
import EDistrict from "../img/E_District.png";
import Nessie from "../img/nessie.gif";
//Map Pending
import MapImageNotFound from "../img/No_Map.png";

function MapInfo() {
  const nuller = {
    brNext: null,
  };
  const [error, setError] = useState([null]);
  const [isDataReady, setDataReady] = useState(false);
  const [currentMapData, setCurrentMapData] = useState();
  const [nextMapData, setNextMapData] = useState();
  const [ltmData, setLTMData] = useState();
  const [currentRankedBRMapData, setCurrentRankedBRMapData] = useState();
  const [nextRankedBRMapData, setNextRankedBRMapData] = useState();
  const [currentmapimage, setCurrentMapImage] = useState(nuller);
  const [nextmapimage, setNextMapImage] = useState(nuller);
  const [currentRankedmapimage, setCurrentRankedMapImage] = useState(nuller);
  const [nextRankedmapimage, setNextRankedMapImage] = useState(nuller);

  let [mapTimer, setMapTimer] = useState({
    timeleftonmap: null,
    rankedTimeLeftOnMap: null,
    mixTapeTimeLeftOnMap: null,
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(Map_Path);
        const data = await response.json();
        updateState(data);
      } catch (error) {
        setDataReady(false);
        setError(ErrorStatusHandler(error.status));
      }
    };

    const updateState = (data) => {
      setCurrentMapData(getMapData(data, 'br_pubs', 'current'));
      setCurrentRankedBRMapData(getRankedMapData(data, 'br_ranked', 'current'));
      setNextMapData(getMapData(data, 'br_pubs', 'next'));
      setNextRankedBRMapData(getRankedMapData(data, 'br_ranked', 'next'));
      setLTMData(getLTMData(data));
    };

    const getMapData = (data, category, type) => ({
      map: data[category][type].map,
      durationMin: data[category][type].DurationInMinutes,
      brStart: data[category].current.end,
      mixTapeName: data.mixtape[type].eventName,
      mixTapeMap: data.mixtape[type].map,
      mixTapeDurationMin: data.mixtape[type].DurationInMinutes,
      mixTapeEpochStart: data.mixtape.current.end,
      mixTapeBackground: data.mixtape[type].asset,
    });

    const getRankedMapData = (data, category, type) => ({
      map: data[category][type].map,
      endTime: data[category][type].end,
    });

    const getLTMData = (data) => {
      const ltm = data.ltm;
      if (!ltm.isActive) {
        return { isActive: data.ltm.isActive };
      }

      if (ltm.rotation === undefined || ltm.rotation === null) {
        return { isActive: false };
      }

      return {
        isActive: data.ltm.isActive,
        start: data.ltm.start,
        name: data.ltm.name,
        end: data.ltm.end,
        currentRotation: getRotationData(data.ltm.rotation.current),
        nextRotation: getRotationData(data.ltm.rotation.next)
      };
    };

    const getRotationData = (rotation) => ({
      isActive: rotation.isActive,
      start: rotation.start,
      end: rotation.end,
      map: rotation.map,
      durationMin: rotation.DurationInMinutes,
      eventName: rotation.eventName,
      asset: rotation.asset,
    });
    fetchData();
  }, [isDataReady]);

  const mapImageMappings = {
    "Storm Point": StormPoint,
    "World's Edge": WorldsEdge,
    "Kings Canyon": KingsCanyon,
    Olympus: Olympus,
    "Broken Moon": BrokenMoon,
    "E-District": EDistrict,
    Unknown: MapImageNotFound,
  };

  const setBrMapImage = (mapName, setBrMapImage, setNextMapImage) => {
    const currentMap = mapImageMappings[mapName] || MapImageNotFound;
    setBrMapImage((currentmapimage.brNext = { brNext: currentMap }));

    const nextMapName = nextMapData.map || "Unknown";
    const nextMap = mapImageMappings[nextMapName] || MapImageNotFound;
    setNextMapImage((nextmapimage.brNext = { brNext: nextMap }));
  };

  const setRankedMapImage = (mapName, setRankedMapImage, setNextRankedMapImage) => {
    const currentRankedMap = mapImageMappings[mapName] || MapImageNotFound;
    setRankedMapImage((currentmapimage.brNext = { brNext: currentRankedMap }));

    const nextRankedMapName = nextRankedBRMapData.map || "Unknown";
    const nextRankedMap = mapImageMappings[nextRankedMapName] || MapImageNotFound;
    setNextRankedMapImage((nextmapimage.brNext = { brNext: nextRankedMap }));
  };

  const doMaps = () => {
    // BR
    setBrMapImage(currentMapData.map, setCurrentMapImage, setNextMapImage);

    // RANKED
    setRankedMapImage(currentRankedBRMapData.map, setCurrentRankedMapImage, setNextRankedMapImage);
  };

  function calcTimeLeft(timeDifference) {
    if (isNaN(timeDifference) || timeDifference < 0) {
      return "updating...";
    }
    var days = Math.floor(timeDifference / 86400);
    var hours = Math.floor((timeDifference % 86400) / 3600);
    var minutes = Math.floor((timeDifference % 3600) / 60);
    var seconds = timeDifference % 60;
    var formattedTime = "";

    if (days === 1) {
      formattedTime += "1 day - ";
    } else if (days > 1) {
      formattedTime += days + " days - ";
    }

    if (hours > 0) {
      formattedTime += (hours < 10 ? "0" : "") + hours + ":";
      formattedTime += (minutes < 10 ? "0" : "") + minutes + ":";
    } else if (minutes > 0) {
      formattedTime += (minutes < 10 ? "0" : "") + minutes + ":";
    }
    if (hours > 0) {
      formattedTime += (seconds < 10 ? "0" : "") + seconds + " hours";
    } else if (minutes > 0) {
      formattedTime += (seconds < 10 ? "0" : "") + seconds + " mins";
    } else {
      formattedTime += (seconds < 10 ? "0" : "") + seconds + " sec";
    }

    return formattedTime;
  }
  useEffect(() => {
    const interval = setInterval(() => {
      if (currentMapData !== undefined && nextMapData !== undefined) {
        //  remove loading
        setDataReady(true);
        doMaps();
        var currentTime = Math.floor(new Date().getTime() / 1000);
        //  Calc mins for BR
        var brStart = calcTimeLeft(nextMapData.brStart - currentTime);
        //  Calc mins for Ranked
        var rankedStart = calcTimeLeft(currentRankedBRMapData.endTime - currentTime);
        //  Calc mins for mixTape
        var mixTapeStart = calcTimeLeft(nextMapData.mixTapeEpochStart - currentTime);
        if (ltmData.isActive) {
          //  Calc mins for LTM Event
          var ltmStart = calcTimeLeft(ltmData.end - currentTime);
          //  Calc mins for LTM Event
          var ltmMapStart = calcTimeLeft(ltmData.currentRotation.end - currentTime);
        }
        localStorage.setItem("map", currentMapData.map);
        localStorage.setItem("time", brStart);
        setMapTimer({
          //  For timer
          timeleftonmap: brStart,
          rankedTimeLeftOnMap: rankedStart,
          mixTapeTimeLeftOnMap: mixTapeStart,
          ltmEndDate: ltmStart,
          ltmMapEndDate: ltmMapStart,
        });
        //  lets check for a map(s) change if we are below the time and reload if condition hit
        let brMapChangeCondition = nextMapData.brStart - currentTime <= -1;
        let rankedMapChangeCondition = currentRankedBRMapData.endTime - currentTime <= -1;
        let mixTapeMapChangeCondition = nextMapData.mixTapeEpochStart - currentTime <= -1;
        if (brMapChangeCondition || rankedMapChangeCondition || mixTapeMapChangeCondition) {
          setTimeout(() => {
            window.location.reload(false);
          }, 1000);
        }
      }
    }, 1000);
    return () => clearInterval(interval);
  });

  // const cachingSite = "https://wsrv.nl/?url=apexlegends.me";
  const cachingSite = "";

  const BRNow = {
    url: cachingSite + currentmapimage.brNext,
  };
  const BRNext = {
    url: cachingSite + nextmapimage.brNext,
  };

  const BRRanked = {
    url: cachingSite + currentRankedmapimage.brNext,
  };

  const BRRankedNext = {
    url: cachingSite + nextRankedmapimage.brNext,
  };

  const convertMinutes = (minutes) => {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;

    let hourStr = hours > 1 ? "hours" : "hour";
    let minuteStr = remainingMinutes > 1 ? "mins" : "min";

    if (hours === 0) {
      return `${remainingMinutes} ${minuteStr}`;
    } else if (remainingMinutes === 0) {
      return `${hours} ${hourStr}`;
    } else if (minutes >= 90) {
      return `${hours} ${hourStr} ${remainingMinutes} ${minuteStr}`;
    } else {
      return `${minutes} ${minuteStr}`;
    }
  };

  if (!isDataReady) {
    return (
      <div className="text-center">
        <Image src={Nessie} fluid />
        {error[0] !== null ? (
          <h1 style={{ color: "red" }}>{error}</h1>
        ) : (
          <h1>
            Loading <b>Map</b> Information
            <Spinner animation="grow" size="sm" variant="danger" />
          </h1>
        )}
      </div>

    );
  } else {
    return (
      <div className="text-center">
        {/* Extension */}
        <Container>{extension}</Container>

        {/* Apex Map Rotation: LTM Active */}
        {ltmData.isActive &&
          <Container>
            <br />
            <h2 style={{ marginBottom: "unset" }}>
              <b>{ltmData.name}<Spinner animation="grow" variant="danger" style={{ animation: "2s linear infinite spinner-grow" }} /></b>
            </h2>
            {mapTimer.ltmEndDate !== "updating..." &&
              <b style={{ top: "100px" }}>Available For {mapTimer.ltmEndDate}</b>}
            <Box style={{ pointerEvents: "none" }}>
              <ImageButton style={{ width: "100%" }}>
                <ImageSrc
                  style={{
                    backgroundImage: `url(${ltmData.currentRotation.asset}), url(${MapImageNotFound})`,
                  }}
                />
                <Imagee>
                  <div align="center">
                    <b>{ltmData.currentRotation.eventName}</b>
                    <h2>
                      <b>{ltmData.currentRotation.map}</b>
                    </h2>
                    for {convertMinutes(ltmData.currentRotation.durationMin)}
                  </div>
                </Imagee>
              </ImageButton>
            </Box>
            {/* Timer  */}
            <Container>
              <h4>
                Time left on <b>{ltmData.currentRotation.map}</b> -{" "}
                {mapTimer.ltmMapEndDate ? mapTimer.ltmMapEndDate : "Loading.. mins"}
              </h4>
            </Container>
            <Box style={{ pointerEvents: "none" }}>
              <ImageButton style={{ width: "100%" }}>
                <ImageSrc
                  style={{
                    backgroundImage: `url(${ltmData.nextRotation.asset}), url(${MapImageNotFound})`,
                  }}
                />
                <Imagee>
                  <div align="center">
                    <b>{ltmData.nextRotation.eventName}</b>
                    <h2>
                      <b>{ltmData.nextRotation.map}</b>
                    </h2>
                    for {convertMinutes(ltmData.nextRotation.durationMin)}
                  </div>
                </Imagee>
              </ImageButton>
            </Box>
          </Container>
        }

        {/* Apex Map Rotation: Battle Royale */}
        <Container>
          <br />
          <h3>
            <b>Battle Royale</b>
          </h3>
          <Box style={{ pointerEvents: "none" }}>
            <ImageButton
              style={{
                width: "100%",
              }}
            >
              <ImageSrc
                style={{
                  backgroundImage: `url(${BRNow.url}), url(${MapImageNotFound})`,
                }}
              />
              <Imagee>
                <div align="center">
                  Now{" "}
                  <h2>
                    <b>{currentMapData.map}</b>
                  </h2>
                  for {convertMinutes(currentMapData.durationMin)}
                </div>
              </Imagee>
            </ImageButton>
          </Box>
          {/* Timer  */}
          <Container>
            <h4>
              Time left on <b>{currentMapData.map}</b> -{" "}
              {mapTimer.timeleftonmap ? mapTimer.timeleftonmap : "Loading.. mins"}
            </h4>
          </Container>
          <Box style={{ pointerEvents: "none" }}>
            <ImageButton
              style={{
                width: "100%",
              }}
            >
              <ImageSrc
                style={{
                  backgroundImage: `url(${BRNext.url}), url(${MapImageNotFound})`,
                }}
              />
              <Imagee>
                <div align="center">
                  Next <h2><b>{nextMapData.map}</b></h2>
                  for {convertMinutes(nextMapData.durationMin)}
                </div>
              </Imagee>
            </ImageButton>
          </Box>
        </Container>

        {/* Apex Map Rotation: Battle Royale Ranked */}
        <Container>
          <br />
          <h3>
            <b>Battle Royale Ranked</b>
          </h3>
          <Box style={{ pointerEvents: "none" }}>
            <ImageButton
              style={{
                width: "100%",
              }}
            >
              <ImageSrc
                style={{
                  backgroundImage: `url(${BRRanked.url}), url(${MapImageNotFound})`,
                }}
              />
              <Imagee>
                <div align="center">
                  Now{" "}
                  <h2>
                    <b>{currentRankedBRMapData.map}</b>
                  </h2>
                </div>
              </Imagee>
            </ImageButton>
          </Box>
          {/* Timer  */}
          <Container>
            <h4>
              Time left on <b>{currentRankedBRMapData.map}</b> -{" "}
              {mapTimer.rankedTimeLeftOnMap ? mapTimer.rankedTimeLeftOnMap : "Loading.. mins"}
            </h4>
          </Container>
          <Box style={{ pointerEvents: "none" }}>
            <ImageButton
              style={{
                width: "100%",
              }}
            >
              <ImageSrc
                style={{
                  backgroundImage: `url(${BRRankedNext.url}), url(${MapImageNotFound})`,
                }}
              />
              <Imagee>
                <div align="center">
                  Next
                  <h2>
                    <b>{nextRankedBRMapData.map}</b>
                  </h2>
                </div>
              </Imagee>
            </ImageButton>
          </Box>
        </Container>
        <br />

        {/* Apex Map Rotation: MIXTAPE */}
        <Container>
          <h3>
            <b>Mixtape</b>
          </h3>
          <Box style={{ pointerEvents: "none" }}>
            <ImageButton
              style={{
                width: "100%",
              }}
            >
              <ImageSrc
                style={{
                  backgroundImage: `url(${currentMapData.mixTapeBackground}), url(${MapImageNotFound})`,
                }}
              />
              <Imagee>
                <div align="center">
                  <b>{currentMapData.mixTapeName}</b>
                  <h2>
                    <b>{currentMapData.mixTapeMap}</b>
                  </h2>
                  for {convertMinutes(currentMapData.mixTapeDurationMin)}
                </div>
              </Imagee>
            </ImageButton>
          </Box>
          {/* Timer  */}
          <Container>
            <h4>
              Time left on <b>{currentMapData.mixTapeMap}</b> -{" "}
              {mapTimer.mixTapeTimeLeftOnMap ? mapTimer.mixTapeTimeLeftOnMap : "Loading.. mins"}
            </h4>
          </Container>
          <Box style={{ pointerEvents: "none" }}>
            <ImageButton
              style={{
                width: "100%",
              }}
            >
              <ImageSrc
                style={{
                  backgroundImage: `url(${nextMapData.mixTapeBackground}), url(${MapImageNotFound})`,
                }}
              />
              <Imagee>
                <div align="center">
                  <b>{nextMapData.mixTapeName}</b>
                  <h2>
                    <b>{nextMapData.mixTapeMap}</b>
                  </h2>
                  for {convertMinutes(nextMapData.mixTapeDurationMin)}
                </div>
              </Imagee>
            </ImageButton>
          </Box>
        </Container>
        <br />

        {/* Footer */}
        {defaultFooter}
      </div>
    );
  }
}

const ImageButton = styled(ButtonBase)(({ theme }) => ({
  position: "relative",
  height: 125,
  [theme.breakpoints.down("sm")]: {
    width: "100% !important",
    height: 100,
  },
  "&:hover, &.Mui-focusVisible": {
    zIndex: 1,
    "& .MuiImageBackdrop-root": {
      opacity: 0.15,
    },
    "& .MuiImageMarked-root": {
      opacity: 0,
    },
    "& .MuiTypography-root": {
      border: "4px solid currentColor",
    },
  },
}));

const ImageSrc = styled("span")({
  position: "absolute",
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  backgroundSize: "cover",
  backgroundPosition: "center 40%",
  borderRadius: 7,
});

const Imagee = styled("span")(({ theme }) => ({
  position: "absolute",
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: theme.palette.common.white,
}));

export default MapInfo;
